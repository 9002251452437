<template>
  <mainDiv>    

    <!-- Christmas Tea -->
    <div class="row">
      <div>
      <img
        v-if="isMobile"
        class="img9"
        src="../../assets/events/WindmereChristmasTea2024.png"
      />   
    </div>        
    
    <img
        v-if="!isMobile"
        class="img9"
        src="../../assets/events/WindmereChristmasTea2024.png"
      />     
      <p>
        If you’ve stayed with us over the holiday season, you know that it’s the most wonderful time of year at the Inn. Brimming with old country charm, candlelight, and touches of Christmas everywhere you look, there’s no better place to cozy up by the fire and embrace the wonder of the season. <br><br>
        Our third annual Christmas Tea is coming up December 6-7. It’s the perfect way to ring in the season for you and the special someone(s) in your life. Check-in for a peaceful overnight stay December 6th and enjoy First Friday in historic downtown Auburn that evening. The Christmas Tea will be served the following morning. 
      </p>        
    </div>
    <!-- <div class="space1"></div> -->
    <div>We have a limited number of rooms available, so book yours soon! </div>
    <div class="space2"></div>
    <div>
      The holiday season is all about connecting and making memories with loved ones, and what more enjoyable way to do that than over a pot of tea, the clink of fine china, dainty delicacies, and a dash of nostalgia… all against the backdrop of live harp music, a crackling fire, and the Christmas-draped Inn? Whether you want to come with a girlfriend, sister, husband, mom, or daughter, we have room at the table for you. 
    </div>
    
    <div class="space15"></div>  

    <div class="text2">
      <div class="title5">DETAILS</div>
      <div class="space15"></div>
      <div><span style="font-weight: 400">Friday, December 6th</span></div>
      <div class="space15"></div>
      <div>Check-in: 3pm</div>      
      <div>First Friday: 5-8pm</div>
      <div class="space15"></div>      
      <div><span style="font-weight: 400">Saturday, December 7th</span></div>
      <ul>
        <li>Christmas Tea <span class="ast">*</span>: 9:30am</li>
        <li>Live harp music: 9:30am - 10am</li>        
      </ul>
      <div><span class="ast">*</span> The tea is designed to be an enjoyable experience for girls ages 10 and up.</div>      
      
      <br>
      <div><span style="font-weight: 400">Christmas Tea Menu Highlights</span></div>      
      <ul>
        <li>Seasonal fruit</li>
        <li>Scones</li>
        <li>Egg casserole</li>
        <li>Tea sandwiches</li>
        <li>Dessert</li>
        <li>Tea &amp; Coffee</li>
      </ul>
      <div>The Christmas Tea will be hosted in our elegant Tea Room, which will be decorated in a Victorian style for the season.</div>
      <br>
      <div><span style="font-weight: 400">Cost</span></div>     
      <div>Rates vary based on room. There is no additional cost for the Christmas Tea. Browse our rooms <span class="link1" @click="dispatch('rooms','rooms')">here</span></div>
      <br>      
      <div><span style="font-weight: 400">First Friday &amp; Other Things to Do During Your Stay</span></div>
      <ul>
        <li><span class="link1" @click="openLink('https://facebook.com/events/s/seasons-greetings-and-holiday-/284024451355387/')">First Friday</span> in historic downtown Auburn featuring live music, local vendors, food &amp; drink specials, and more. Wear your best ugly sweater!
        <ul>
          <li>5-8pm Friday, Dec. 6th.</li>
        </ul>
        </li>
        <li>Relax by the fire with a book or movie from the Inn’s library</li>
        <li>Enjoy a soak in a private jetted tub (<span class="link1" @click="dispatch('rooms','windmere')">Windmere</span> and <span class="link1" @click="dispatch('rooms','sunsrise')">Sunrise</span> Suites)</li>
        <li>Snacks will be available throughout your stay</li>
        <li>Holiday browsing at unique downtown boutiques</li>
        <li>Dining options for every palate</li>
        <li>Explore nearby <span class="link1" @click="openLink('https://visitshipshewana.org/shipshewana/')">Amish Country</span></li>
      </ul>
    </div>


    <!-- **** PREVIOUS EVENTS **** -->
    <div class="space1"></div>
    <div class="text6"><img alt="Event Separater" v-if="!isMobile" class="img3" src="../../assets/events/previousEventLine.png" /> Previous Events <img alt="Event Separator" v-if="!isMobile" class="img3" src="../../assets/events/previousEventLine2.png" /></div>
    <div class="space1">  </div>
    <!-- **************** -->
    
    <!-- Pat Delagrange  - Cookies & Canvas-->
    <div v-if="!isMobile" class="space1"></div>
    <div class="row1">
      <img
        v-if="isMobile"
        class="img1"
        src="../../assets/events/CookiesCanvas2024Header.png"
      />      
      <div class="text5">
        <div class="space2"></div>
        <div>
          Spring is almost here, and it’s a beautiful time of year to visit the
          Inn - you’ll be surrounded by birdsong, softly-budding trees, and the
          first flowers of the season.
        </div>
      </div>
      <img
        v-if="!isMobile"
        class="img1"
        src="../../assets/events/CookiesCanvas2024Header.png"
      />
    </div>
    <div class="space2"></div>
    <div class="text2">
      <span style="font-weight: 400">Join us April 12</span> for our 2nd annual
      Cookies & Canvas event - an evening of painting, refreshments, and fun. The event was a huge success last year, and we’re excited to be able to offer it again.
    </div>
    <div class="space1"></div>
    <div class="text2">
      Taught by renowned local artist
      <span
        class="link1"
        @click="openLink('https://www.facebook.com/ArtbyPatDelagrange/')"
        >Pat Delagrange</span
      >, the class will be lighthearted and accessible to any level of painter.
      Pat will walk guests step-by-step through creating their own oil painting of the beautiful bouquet pictured above.
    </div>
    <div class="space1"></div>
    <div class="text2">
      We have many of Pat’s paintings displayed throughout the Inn. She also
      painted each room sign and freehanded the flowers on the
      <span class="link1" @click="dispatch('garden', 'rooms')"
        >Garden Room’s</span
      >
      walls.
    </div>
    <div class="space1"></div>
    <div class="text2">
      She’s an incredible artist and person - you won’t want to miss it!
    </div>
    <div class="space1"></div>
    <div class="title5">SCHEDULE</div>
    <div class="b1">
      <div class="b2">
        <div
          style="display: flex; flex-direction: column; justify-content: center"
        >
          <div class="space1"></div>
          <div><span style="font-weight: 400">Friday, April 12</span></div>
          <ul>
            <li>3pm - Check-in</li>
            <li>7-9pm - Painting class with Pat Delagrange</li>
          </ul>
          <div><span style="font-weight: 400">Saturday, April 13</span></div>
          <ul>
            <li>9am - Breakfast</li>
            <li>12pm - Check-out*</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="text4">
      * Guests who would like to stay until Sunday will receive 25% off the 2nd
      night's stay
    </div>
    <div class="space1"></div>
    <div class="title5">COST</div>
    <div class="text2">
      Class fee is $50 (in addition to room cost) and includes all supplies
    </div>
    <div class="space1"></div>
    <div class="title5">REFRESHMENTS</div>
    <div class="text2">
      Cookies, coffee, and tea will be served. You’re welcome to bring your own
      wine! We’ll have wine glasses set out.
    </div>
    <div class="space1"></div>    
    <div class="b1">
      <div class="b4">
        <div class="title5">ABOUT THE ARTIST</div>
        <div v-if="isMobile" class="space1"></div>
        <div class="text2">
          <span
            class="link1"
            @click="openLink('https://www.facebook.com/ArtbyPatDelagrange/')"
            >Pat Delagrange</span
          >
          is a self-taught artist, well-regarded in northeastern Indiana for her
          elaborate murals and whimsical artwork. Her business initially grew
          out of a desire to paint and still be a full-time mom.
        </div>
        <!-- <div class="space1"></div> -->
        <div class="text2">
          When husband Merv bought a lumberyard in the small town of Grabill,
          Indiana, Pat's vision of a "street" of retail shops blossomed into
          reality with The Old Lumberyard Shoppes. Fourteen years later, Pat and
          Merv sold the business and moved to Auburn, where they purchased an
          old church building (now known and loved as
          <span
            class="link1"
            @click="
              openLink(
                'https://www.facebook.com/profile.php?id=100063625119629'
              )
            "
            >The Cranberry Cottage</span
          >, anticipating an intriguing new chapter of their lives. Now a retired grandparent, Pat stays busy with commissioned work and art
      shows, enjoying traveling and gardening in-between.
        </div>
      </div>
      <div class="b3">
        <img alt="Pat Delagrange" class="img4" src="../../assets/events/PatDelagrangeHeadshot.png" />
      </div>
    </div>
    <!-- <div class="space1"></div> -->
    <!-- <div class="text2">
      
    </div>   -->
    
  <!-- **** DIVIDER **** -->
    
  <div class="text6"><img alt="Event Separator" class="img5" src="../../assets/events/previousEventLine3.png" /></div>
    <div v-if="!isMobile" class="space1"></div>      
    <!-- **************** -->

    <!-- ******** Sourdough Class ******** -->        
    <div v-if="!isMobile" class="space1"></div>
    <div class="row1">
    <img       
        alt="Sourdough Header"
        class="img6"
        src="../../assets/events/SourdoughHeader.png"
      />      
    </div>
    <div class="space2"></div>
    <div class="space1" v-if="!isMobile"></div>
    <div class="text2">
      <span style="font-weight: 400">Join us at the Inn</span> for a sourdough making class and tasting with sourdough devotee Jill Smith the weekend of <span style="font-weight: 400">March 15.</span>
    </div>
    <div class="space1"></div>
    <div class="text2">
      The class will include demonstrations of step-by-step methods for creating a loaf of sourdough - including enjoying its aroma in the kitchen as it bakes! A detailed resource packet and a starter to take home will also be included.
    </div>

    <div class="space1"></div>
    <div class="title5">SCHEDULE</div>
    <div class="b1">
      <div class="b2">
        <div
          style="display: flex; flex-direction: column; justify-content: center"
        >
          <div class="space1"></div>
          <div><span style="font-weight: 400">Friday, March 15</span></div>
          <ul>
            <li>3pm - Check-in</li>
          </ul>
          <div><span style="font-weight: 400">Saturday, March 16</span></div>
          <ul>
            <li>8:30am - Breakfast</li>
            <li>9:30am - Class</li>
            <li>12pm - Lunch (soup & a warm slice of sourdough)</li>
            <li>1pm - Check-out*</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="text4">
      * Guests who would like to stay until Sunday will receive 25% off the 2nd
      night's stay
    </div>
    <div class="space1"></div>
    <div class="title5">COST</div>
    <div class="text2">
      Class fee is $30 (in addition to room cost) and includes a detailed resource packet and a sourdough starter to take home.
    </div>
    <div class="space1"></div>    
    <div class="title5">ABOUT THE INSTRUCTOR</div>
    <div v-if="isMobile" class="space1"></div>
    <div class="text2">
      Jill Smith, RN retired from hospice work in California during the pandemic. She and her husband Bob moved to Indiana in 2020. As a dementia specialist, she continues to provide private consultations and community classes. New endeavors are found in gardening, canning and sourdough making.
    </div>
    <div class="space1"></div>
    <div class="text2">
      Sourdough making has captured her attention and devotion, which includes making loaves and many other items from sourdough. These new hobbies have provided a balance to the lighter side of life stacked up against the needs of folks who are living with dementia.
    </div>
    
    <!-- **** DIVIDER **** -->
    
    <div class="text6"><img alt="Event Separator" class="img5" src="../../assets/events/previousEventLine3.png" /></div>
    <div v-if="!isMobile" class="space1"></div>      
    <!-- **************** -->
    
    <!-- Christmas Tea -->
    <div class="row">
      <div>
      <img
        v-if="isMobile"
        class="img8"
        src="../../assets/events/Christmas2023/WindmereChristmasTea-EmailHeader.png"
      />   
    </div>        
    
    <img
        v-if="!isMobile"
        class="img8"
        src="../../assets/events/Christmas2023/WindmereChristmasTea-EmailHeader.png"
      />     
      <p>
        If you’ve stayed with us over the holiday season, you know that it’s the most wonderful time of year at the Inn. Brimming with old country charm, candlelight, and touches of Christmas everywhere you look, there’s no better place to cozy up by the fire and embrace the wonder of the season.<br><br>
        Our second annual Christmas Tea is coming up on December 2nd. It’s the perfect way to ring in the season for you and the special someone(s) in your life. Come for a peaceful overnight stay December 1st complimented by the delicious Christmas Tea the following morning, or pair it with a showing of The Nutcracker Ballet in nearby Fort Wayne that afternoon.
      </p>        
    </div>
    <div class="space1"></div>
        <div>We have a limited number of rooms available, so book yours soon! </div>
    <div class="space2"></div>
    <div>
      The holiday season is all about connecting and making memories with loved ones, and what more enjoyable way to do that than over a pot of tea, the clink of fine china, dainty delicacies, and a dash of nostalgia… all against the backdrop of live harp music, a crackling fire, and the Christmas-draped Inn? Whether you want to come with a girlfriend, sister, husband, mom, or daughter, we have room at the table for you.
    </div>
    
    <div class="space15"></div>  

    <div class="text2">
      <div class="title5">DETAILS</div>
      <div class="space15"></div>
      <div><span style="font-weight: 400">Friday, December 1st</span></div>
      <div class="space15"></div>
      <div>Check-in: 3pm</div>      
      <div class="space15"></div>      
      <div><span style="font-weight: 400">Saturday, December 2nd</span></div>
      <ul>
        <li>Christmas Tea<span class="ast">*</span>: 9:30am</li>
        <li>Live harp music: 9:30am - 10am</li>
        <li><span class="link1" @click="openLink('https://tickets.artstix.org/TheatreManager/6/tmEvent/tmEvent3997.html')">Nutcracker Ballet</span><span class="ast">**</span>: 2pm and 7:30pm at the Arts United Center, 303 E Main St,, Fort Wayne, IN 46802</li>
      </ul>
      <div><span class="ast">*</span> The tea is designed to be an enjoyable experience for girls ages 10 and up.</div>
      <div><span class="ast">**</span> Featuring the <span class="link1" @click="openLink('https://fwphil.org/')">Fort Wayne Philharmonic</span> and <span class="link1" @click="openLink('https://fortwayneballet.org')">Fort Wayne Ballet</span></div>
      
      <br>
      <div><span style="font-weight: 400">Christmas Tea Menu Highlights</span></div>      
      <ul>
        <li>Seasonal fruit</li>
        <li>Scones</li>
        <li>Egg casserole</li>
        <li>Tea sandwiches</li>
        <li>Dessert</li>
        <li>Tea & Coffee</li>
      </ul>
      <div>The Christmas Tea will be hosted in our elegant Tea Room, which will be decorated in a Victorian style for the season.</div>
      <br>
      <div><span style="font-weight: 400">Cost</span></div>     
      <div>Rates vary based on room. There is no additional cost for the Christmas Tea. Browse our rooms <span class="link1" @click="dispatch('rooms','rooms')">here</span></div>
      <br>
      <div>If you want to experience The Nutcracker during your stay, tickets can be booked and paid for separately through the Fort Wayne Ballet (see below).</div>
      <br>
      <div><span style="font-weight: 400">The Nutcracker Ballet</span></div>   
      <div>“Holiday magic abounds, as Clara’s toy Nutcracker springs to life and sweeps her off to a land of sweets and wonderment! Fairies and snow flurries shape this enchantment that must be dreamed to be believed. A tale for children young and old, the precious characters and familiar musical themes make <span class="it">Fort Wayne Ballet’s The Nutcracker</span> the very heart of our favorite traditions.” - The Fort Wayne Ballet</div> 
      <br>
      <div>For many of us, no Christmas season is complete without experiencing the magic of The Nutcracker. If this is you, pair your Christmas Tea with a showing of The Nutcracker in nearby Fort Wayne (a 30-minute drive from the Inn).</div>
      <br>
      <div>Tickets start at $59. Learn more and book tickets <span class="link1" @click="openLink('https://tickets-center.com/search/Embassy-Theatre/The-Nutcracker-tickets/?venueId=508&performerId=620&venueName=Embassy+Theatre&performerName=The+Nutcracker&vaid=519&pfaid=65&tagid=114&nid=1&accid=2750581513&campaignid=20550155404&cid=673716316041&akwd=the%20nutcracker%20fort%20wayne&mt=b&network=g&dist=s&adposition=&device=c&ismobile=false&devicemodel=&placement=&target=&random=8204228977901835865&loc_physical_ms=9012211&loc_interest_ms=9016342&exid=&fiid=&vx=0&gclid=CjwKCAjwsKqoBhBPEiwALrrqiLSLOeQu5bJxvYXvCDd54ZmojSG-VHr2eiQpwC908HedX7dotHKnaBoCflYQAvD_BwE')">here</span></div>
      <br>
      <div><span style="font-weight: 400">Other Things to Do During Your Stay</span></div>
      <ul>
        <li><span class="link1" @click="openLink('https://m.facebook.com/events/downtown-auburn-historic-district/first-friday-ugly-sweater/696083444820010/')">First Friday</span> in historic downtown Auburn featuring live music, local vendors, food & drink specials, and more. Wear your best ugly sweater!
        <ul>
          <li>5-8pm Friday, Dec. 1st.</li>
        </ul>
        </li>
        <li>Relax by the fire with a book or movie from the Inn’s library</li>
        <li>Enjoy a soak in a private jetted tub (<span class="link1" @click="dispatch('rooms','windmere')">Windmere</span> and <span class="link1" @click="dispatch('rooms','sunsrise')">Sunrise</span> Suites)</li>
        <li>Snacks will be available throughout your stay</li>
        <li>Holiday browsing at unique downtown boutiques</li>
        <li>Dining options for every palate</li>
        <li>Explore nearby <span class="link1" @click="openLink('https://visitshipshewana.org/shipshewana/')">Amish Country</span></li>
      </ul>
    </div>

    <!-- **** DIVIDER **** -->
    <div class="space1"></div>    
    <div class="text6"><img alt="Event Separator" class="img5" src="../../assets/events/previousEventLine3.png" /></div>
    <div v-if="!isMobile" class="space1"></div>      
    <!-- **************** -->

    <!-- ******** Cooking Class ******** -->        
    <div v-if="!isMobile" class="space1"></div>
    <div class="row1">
    <img     
        alt="Cooking Class"   
        class="img6"
        src="../../assets/events/CookingClass/CookingClassHeader.png"
      />      
    </div>
    <div class="space3"></div>
    <img        
        alt="Cooking Class"
        class="img7"
        src="../../assets/events/CookingClass/TOS.png"
      />    
    <div class="row1">        
      <div class="b5">We are excited to offer the first of our new seasonal cooking class series at the Inn at Windmere, in tandem with Chef Stacy Kosha.</div>
    </div>
    <div class="space15"></div>
    <img        
        alt="Taste Of Summer"
        class="img7"
        src="../../assets/events/CookingClass/TasteofSummerGraphic.png"
      />
    <!-- <div class="text2">
      <span style="font-weight: 400">Join us July 14th</span> for a book 
      signing event with bestselling author Colleen Coble.      
    </div>
    <div class="space1"></div> -->  
    <div class="space15"></div>  

    <div class="space1"></div>
    <div class="b6">
      <div class="b7">
        <div class="title5">SCHEDULE</div>
        <div class="b1">
          <div class="b21">
            <div
              style="display: flex; flex-direction: column; justify-content: center"
            >
              <div class="space1"></div>
              <div><span style="font-weight: 400">Saturday, Sept. 9</span></div>
              <ul>
                <li>3pm Check-in 5pm Cooking demo & taste testing</li>
                <li>6pm Plated main serving</li>
                <li>7pm Dessert (served in the gazebo, weather permitting)</li>
              </ul>
              <div><span style="font-weight: 400">Sunday, Sept. 10</span></div>
              <ul>
                <li>9am - Breakfast</li>
                <li>12pm - Check-out</li>
              </ul>
            </div>
          </div>
        </div>    
      </div>
      <div class="b7">
        <div class="title5">MENU</div>
        <div class="b1">
          <div class="b21">
            <div
              style="display: flex; flex-direction: column; justify-content: center"
            >
              <div class="space1"></div>
              <div><span style="font-weight: 400">Refresher</span></div>
              <ul>
                <li>Iced blueberry hibiscus tea</li>
              </ul>
              <div><span style="font-weight: 400">Appetizers</span></div>
              <ul>
                <li>Spinach walnut pesto with flaxseed crackers</li>
                <li>Dilled cashew cream with cucumber rounds</li>
              </ul>
              <div><span style="font-weight: 400">Main</span></div>
              <ul>
                <li>Spiralized zucchini with fresh marinara sauce</li>
                <li>Beet hummus with crudites</li>
              </ul>
              <div><span style="font-weight: 400">Dessert</span></div>
              <ul>
                <li>Surprise! To be announced</li>
              </ul>
            </div>
          </div>
        </div>    
      </div>
    </div>
    <div class="space1"></div>
    <div class="b6">
      <div class="b8">
        <div class="title5">THE CHEF</div>
        <div class="text2">
          Chef Stacy specializes in gluten-free and plant-based meal preparation and baking. She received her training as a Natural Chef at Bauman College in beautiful Boulder, Colorado. Using SOUL (seasonal, organic, unrefined, and local) foods, she experienced the culinary arts using the finest ingredients.
        </div>
        <div v-if="isMobile">
          <div class="space1"></div>
          <img        
          alt="Chef Image"
          class="img7"
          src="../../assets/events/CookingClass/ChefPhoto.png"
          />
        </div>
        <div class="space1"></div>
        <div class="title5">COST</div>
        <div class="text2">
          <span style="font-weight: 400">$35</span> (in addition to room fee. Guests who would like to stay 2 nights will receive 25% off the 2nd night's stay.)
        </div>
      </div>
      <div class="b9" v-if="!isMobile">
        <img       
        alt="Chef Image" 
        class="img7"
        src="../../assets/events/CookingClass/ChefPhoto.png"
      />      
      </div>
    </div>

    <!-- **** DIVIDER **** -->
    <div class="space1"></div>    

    <div class="text6"><img alt="Event Separator" class="img5" src="../../assets/events/previousEventLine3.png" /></div>

    <div v-if="!isMobile" class="space1"></div>      
    <!-- **************** -->

    <!-- Colleen Coble -->
    <div v-if="!isMobile" class="space1"></div>
    <div class="row1">
      <img
        v-if="isMobile"
        class="img1"
        src="../../assets/events/Current/ColleenCobleHeader.png"
      />
      <div>
        <!-- <div class="title1">April 2023 Specials</div> -->
        <div class="space2"></div>
        <div>
          <span style="font-weight: 400">Join us July 14th</span> for a book 
          signing event with bestselling author <span class="link1" @click="openLink('http://www.colleencoble.com/')">Colleen Coble</span>.      
        </div>
        <div class="space15"></div>
        <div class="space1"></div>
        <div>Coble will talk about how she got started writing and where she gets her inspiration for novels. She will also answer questions about the publishing process.</div>        
      </div>
      <img
        v-if="!isMobile"
        class="img1"
        src="../../assets/events/Current/ColleenCobleHeader.png"
      />
    </div>
    <div class="space1"></div>
        <div>Books will be available for purchase, or bring your own for her to sign.</div>
    <div class="space2"></div>
    <!-- <div class="text2">
      <span style="font-weight: 400">Join us July 14th</span> for a book 
      signing event with bestselling author Colleen Coble.      
    </div>
    <div class="space1"></div> -->  
    <div class="space15"></div>  

    <div class="text2">
      <div class="title5">DETAILS</div>
      <div class="space15"></div>
      <div>Doors open at <span style="font-weight: 400">6:45pm</span></div>
      <div class="space15"></div>
      <div>Registration fee: <span style="font-weight: 400">$5</span> (payable at the door with cash or a check made out to Inn at Windmere)</div>      
      <div class="space15"></div>      
      <div class="b1">
        <div class="b31">
        <img alt="Colleen Coble" class="img41" src="../../assets/events/Current/ColleenCobleNewestBook.png" />
      </div>
        <div class="b41">          
          <div>Inn at Windmere - <span style="font-weight: 400">2077 CR 40, Auburn, IN</span></div>      
          <div class="space15"></div>
          <div><span style="font-weight: 400">Space is limited!</span> Call <span style="font-weight: 400">260-927-5116</span> today to reserve your seat.</div>      
          <div class="space15"></div>
          <div class="space1"></div>
          <div class="title5">ABOUT THE AUTHER</div>
          <div v-if="isMobile" class="space1"></div>
          <div>Best-selling romantic suspense author Colleen Coble’s novels have won or finaled in awards ranging from the Best Books of Indiana, the ACFW Carol Award, the Romance Writers of America RITA, the Holt Medallion, the Daphne du Maurier, National Readers’ Choice, and the Booksellers Best. </div>
          <div class="space2"></div>
          <div>She has over 4 million books in print and her books have been on the USAToday bestseller list, the ECPA, CBA, Publishers Weekly, and Amazon bestseller lists. </div>
          <div class="space2"></div>
          <div>She writes romantic mysteries because she loves to see justice prevail. Colleen is CEO of American Christian Fiction Writers.</div>
          <div class="space2"></div>
          <div>She lives with her husband Dave in Indiana. <br>Visit her website at <span class="link1" @click="openLink('http://www.colleencoble.com/')">www.colleencoble.com</span>.</div>
        </div>              
      </div>
    </div>
    <div class="text6"><img alt="Event Separator" class="img5" src="../../assets/events/previousEventLine3.png" /></div>


    <!-- Pat Delagrange -->
    <div v-if="!isMobile" class="space1"></div>
    <div class="row1">
      <img
        v-if="isMobile"
        class="img1"
        src="../../assets/events/CookiesCanvasHeader.png"
      />
      <div class="text5">
        <!-- <div class="title1">April 2023 Specials</div> -->
        <div class="space2"></div>
        <div>
          Spring is almost here, and it’s a beautiful time of year to visit the
          Inn - you’ll be surrounded by birdsong, softly-budding trees, and the
          first flowers of the season.
        </div>
      </div>
      <img
        v-if="!isMobile"
        class="img1"
        src="../../assets/events/CookiesCanvasHeader.png"
      />
    </div>
    <div class="space2"></div>
    <div class="text2">
      <span style="font-weight: 400">Join us April 21</span> for our first
      Cookies & Canvas event - an evening of painting, refreshments, and fun.
    </div>
    <div class="space1"></div>
    <div class="text2">
      Taught by renowned local artist
      <span
        class="link1"
        @click="openLink('https://www.facebook.com/ArtbyPatDelagrange/')"
        >Pat Delagrange</span
      >, the class will be lighthearted and accessible to any level of painter.
      Pat will walk guests step-by-step through creating their own version of
      the beautiful tulip painting above (because what sings Spring better than
      a bouquet of tulips?!)
    </div>
    <div class="space1"></div>
    <div class="text2">
      We have many of Pat’s paintings displayed throughout the Inn. She also
      painted each room sign and freehanded the flowers on the
      <span class="link1" @click="dispatch('garden', 'rooms')"
        >Garden Room’s</span
      >
      walls.
    </div>
    <div class="space1"></div>
    <div class="text2">
      She’s an incredible artist and person, and this is something we’ve dreamed
      of doing with her for years. We couldn’t be more excited that it is
      finally coming to reality - you won’t want to miss it!
    </div>
    <div class="space1"></div>
    <div class="title5">SCHEDULE</div>
    <div class="b1">
      <div class="b2">
        <div
          style="display: flex; flex-direction: column; justify-content: center"
        >
          <div class="space1"></div>
          <div><span style="font-weight: 400">Friday, April 21</span></div>
          <ul>
            <li>3pm - Check-in</li>
            <li>7-9pm - Painting class with Pat Delagrange</li>
          </ul>
          <div><span style="font-weight: 400">Saturday, April 22</span></div>
          <ul>
            <li>9am - Breakfast</li>
            <li>12pm - Check-out*</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="text4">
      * Guests who would like to stay until Sunday will receive 25% off the 2nd
      night's stay
    </div>
    <div class="space1"></div>
    <div class="title5">COST</div>
    <div class="text2">
      Class fee is $45 (in addition to room cost) and includes all supplies
    </div>
    <div class="space1"></div>
    <div class="title5">COOKIES</div>
    <div class="text2">
      Cookies, coffee, and tea will be served. You’re welcome to bring your own
      wine! We’ll have wine glasses set out.
    </div>
    <div class="space1"></div>    
    <div class="b1">
      <div class="b4">
        <div class="title5">ABOUT THE ARTIST</div>
        <div v-if="isMobile" class="space1"></div>
        <div class="text2">
          <span
            class="link1"
            @click="openLink('https://www.facebook.com/ArtbyPatDelagrange/')"
            >Pat Delagrange</span
          >
          is a self-taught artist, well-regarded in northeastern Indiana for her
          elaborate murals and whimsical artwork. Her business initially grew
          out of a desire to paint and still be a full-time mom.
        </div>
        <div class="space1"></div>
        <div class="text2">
          When husband Merv bought a lumberyard in the small town of Grabill,
          Indiana, Pat's vision of a "street" of retail shops blossomed into
          reality with The Old Lumberyard Shoppes. Fourteen years later, Pat and
          Merv sold the business and moved to Auburn, where they purchased an
          old church building (now known and loved as
          <span
            class="link1"
            @click="
              openLink(
                'https://www.facebook.com/profile.php?id=100063625119629'
              )
            "
            >The Cranberry Cottage</span
          >, anticipating an intriguing new chapter of their lives.
        </div>
      </div>
      <div class="b3">
        <img alt="Pat Delagrange" class="img4" src="../../assets/events/PatDelagrangeHeadshot.png" />
      </div>
    </div>
    <div class="space1"></div>
    <div class="text2">
      Now a retired grandparent, Pat stays busy with commissioned work and art
      shows, enjoying traveling and gardening in-between.
    </div>
    <div class="space1"></div>
    <div class="text2">
      We’re thrilled to host Cookies & Canvas with Pat at the Inn at Windmere,
      and look forward to seeing our guests masterpieces!
    </div>
  </mainDiv>
</template>

<script>
import MainDiv from "../MainDiv.vue";
export default {
  components: {
    mainDiv: MainDiv,
  },
  data() {
    return {
      isMobile: false,
    };
  },
  mounted() {
    window.addEventListener("resize", this.windowSize);
    this.windowSize();
    window.dispatchEvent(
      new CustomEvent("updateNav", { detail: { selected: "events" } })
    );
  },
};
</script>
<style scoped>
.ast {
  vertical-align:sub;
  color:red;
}
.it {
  font-style: italic;
  font-weight:300
}
.title1 {
  font-size: 40px;
  width: 100%;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 10px;
}
.row1 {
  display: flex;
}
.row2 {
  display: flex;
  width: 100%;
}
.img1 {
  margin-left: 15px;
  width: 60%;
  /* object-fit:contain; */
  float:right;
}
.img2 {
  width: 8%;
  height: 8%;
  float: left;
  margin: 0;
  padding: 0;
  margin-top: 9px;
}
.img6 {
  width:100%;
  object-fit:cover;
}
.p1 {
  width: 45%;
}
p {
  text-align:justify;
}
.list1 > li {
  margin-bottom: 5px;
}
.space1 {
  height: 25px;
}
.space15 {
  height:13px;
}
.space2 {
  height: 20px;
}
.space3 {
  height:40px;
}
.link1 {
  text-decoration: underline;
  color: #6d2328;
  font-weight: 300;
}
.link1:hover {
  cursor: pointer;
}
.img41 {
  width: 100%;
}
.img4 {
  width: 60%;
}
.b1 {
  display: flex;
  width: 100%;
}
.b2 {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.b21 {
  width:80%;
}
.b4 {
    width:50%;
    display:flex;
    flex-direction:column;
    justify-content:space-between;
}
.b3 {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.b41 {
    width:80%;
    display:flex;
    flex-direction:column;
    justify-content:space-between;
}
.b5 {
  width:80%;
  margin-left:10%;
  color: white;
  background-color: #5c8918;  
  display:flex;
  align-items:center;
  padding:15px;
  text-align:center;
  border-radius:2rem;
  font-weight:300;
  margin-top:30px;
  font-size:22px;
  line-height:28px;
  box-sizing: border-box;
}
.b6 {
  width:100%;
  display:flex;
}
.b7 {
  width:50%;
}
.b8 {
  width:60%;
}
.b9 {
  width:40%;
}
.b31 {
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.text4 {
  width: 100%;
  text-align: center;
  font-weight: 400;
  margin-top: 20px;
}
.text5 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: italic;
  text-align: center;
  font-size: 20px;
  line-height: 25px;
}
.text6 {
  width: 100%;
  text-align: center;
  color: #6d2328;
  font-size: 40px;
  font-weight:500;
  font-family:Niconne;
  margin-bottom:30px;
  margin-top:50px;
}
.title5 {
  font-size: 18px;
  font-weight: 600;
  color: #6d2328;
}
.title6 {  
  font-size:50px;
  line-height: 60px;
}
.img3 {
  width:20%;  
  margin-left: 50px;
  margin-right:50px;
}
.img5 {
  width: 40%;
}
.img7 {
  width:60%;
  margin-left:20%;
}
.img8 {
  margin-left: 15px;
  /* width: 60%; */
  /* object-fit:contain; */
  float:right;
}
.img9 {
  width: 100%;  
}
@media only screen and (max-width: 1152px) {
  .row1 {
    display: block !important;
  }
  .img1 {
    margin: 0;
    width: 100%;
    margin-top: 30px;
    float:none;
  }
  .row2 {
    display: block;
  }
  .img2 {
    width: 20%;
    height: 20%;
    margin-right: 9px;
  }
  .img8 {
    margin: 0;
    width: 100%;
    margin-bottom: 30px;
  }
  .margin1 {
    margin-top: 30px;
  }
  .p1 {
    width: 100%;
    padding: 0;
    margin: 0;
  }
  li {
    margin-bottom: 10px;
  }
  .b1 {
    flex-direction: column;
  }
  .b2 {
    width: 100%;
  }
  .b3 {
    width: 100%;
    margin-top:25px;
  }
  .b31 {
    width: 100%;
    margin-top:25px;
  }
  .text5 {
    display:block;
    margin-top: 15px;
    font-size: 20px;
    line-height: 25px;
  }
  .b4 {
    width:100%;
  }
  .b41 {
    width:100%;
  }
  .b5 {
    width:100%;
    margin-left:0;
  }
  .b6{
    display:block;
  }
  .b7 {
    width:100%;
  }
  .b8 {
    width:100%;
  }
  .b9 {
    width:100%;
  }
  .text6{
    font-size:30px;
  }
  .img3 {
  width:15%;  
  margin:8px;
}
}
</style>
